import { useLocation } from "@remix-run/react";
import Container from "~/components/layout/Container";
import { Icon } from "~/components/ui/icon";
import { Social } from "./Social";

export default function Header() {
  const location = useLocation();
  const theme = location.pathname == '/' ? 'light' : 'dark';
  return (
    <header className="flex flex-wrap absolute top-0 left-0 right-0 z-20">
      <div className="flex w-full bg-[#f6f5f5] py-1">
        <Container className="flex items-center justify-between">
          <div className="flex items-center w-1/2 font-raleway">
            <a href="mailto:info@utbsupport.com" className="text-sm mr-2 text-gray-500"><Icon name="bxs:envelope" /> support@utbtrade.com</a>
            <a href="tel:+903326277777" className="text-sm text-gray-500"><Icon name="material-symbols:phone-in-talk-watchface-indicator-sharp" /> +90 332 627 77 77</a>
          </div>
          <div className="w-1/2 flex items-center justify-end [&>a]:text-gray-500">
            <Social />
          </div>
        </Container>
      </div>
      <div className="flex w-full justify-between mix-blend-multiply" style={{ backgroundColor: theme == 'dark' ? '#000000' : '#00000080' }}>
        <Container className="flex items-center justify-between py-3">
          <div className="flex items-center">
            <a href='/'><img src="/assets/utb-trade-support-logo.svg" alt="UTB Logo" className="h-[60px] w-[220px]" /></a>
          </div>
          <nav className="space-x-4 text-white font-raleway font-bold text-[14px]">
            <a href="/" className={`hover:text-[#20b1d7] ${location.pathname == '/' ? 'text-[#20b1d7] font-extrabold' : ''}`}>ANASAYFA</a>
            <a href="/hizmetlerimiz" className={`hover:text-[#20b1d7] ${location.pathname == '/hizmetlerimiz' ? 'text-[#20b1d7] font-extrabold' : ''}`}>HİZMETLERİMİZ</a>
            <a href="/kurumsal" className={`hover:text-[#20b1d7] ${location.pathname == '/kurumsal' ? 'text-[#20b1d7] font-extrabold' : ''}`}>KURUMSAL</a>
            <a href="/blog" className={`hover:text-[#20b1d7] ${location.pathname == '/blog' ? 'text-[#20b1d7] font-extrabold' : ''}`}>BLOG</a>
            <a href="/iletisim" className={`hover:text-[#20b1d7] ${location.pathname == '/iletisim' ? 'text-[#20b1d7] font-extrabold' : ''}`}>İLETİŞİM</a>
          </nav>

        </Container>
      </div>
    </header>
  );
}