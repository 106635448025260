import Container from "~/components/layout/Container";
import { Icon } from "~/components/ui/icon";
import { Social } from "./Social";

export default function Footer() {
  return (
    <footer className="bg-[#333333] text-white">
      <Container className="py-4">
        <div className="flex flex-wrap w-full">
          <div className="flex items-center w-3/12">
            <a href="/" className="w-full flex items-center justify-center">
              <img className="grayscale-100 brightness-200" src="/assets/utb-trade-support-logo.svg" alt="UTB Support" width={220} height={60} />
            </a>
          </div>
          <div className="w-6/12 px-10 flex items-center">
            <ul className="flex justify-between w-full [&>li]:text-[14px] [&>li]:font-medium [&>li]:text-white [&>li:before]:content-['\203A'] [&>li:before]:mr-1 [&>li:hover:before]:text-blue-400">
              <li><a href="/">Anasayfa</a></li>
              <li><a href="/hizmetlerimiz">Hizmetlerimiz</a></li>
              <li><a href="/kurumsal">Kurumsal</a></li>
              <li><a href="/blog">Blog</a></li>
              <li><a href="/iletisim">İletişim</a></li>
            </ul>
          </div>
          <div className="w-3/12 flex items-center gap-2 justify-end -mt-24">
            <a href="https://t.me/utbsupport" className="bg-[#268ec0] p-2 rounded-full hover:text-blue-400"><Icon name="mdi:telegram" size="xl" /></a>
            <a href="https://wa.me/905331234567" className="bg-[#25d366] p-2 rounded-full hover:text-blue-400"><Icon name="mingcute:whatsapp-fill" size="xl" /></a>
          </div>
        </div>
      </Container>
      <div className="flex items-center font-roboto not-italic justify-between bg-[#15437b] py-2">
        <Container className="flex items-center">
          <div className="w-4/12"></div>
          <div className="w-4/12 text-center text-[14px]">
            <p>&copy; {new Date().getFullYear()} Tüm hakları saklıdır.</p>
          </div>
          <div className="w-4/12 flex items-center gap-2 justify-end">
            <Social />
          </div>
        </Container>
      </div>
    </footer>
  );
}